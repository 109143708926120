<template>
  <div class="success-enter-server-container">
    <el-result
      v-if="type === 1"
      icon="warning"
      title="当前不能确认完成服务"
      sub-title="当前服务订单的关联加购订单有售后中产品，请在产品售后完成后确认完成服务"
    >
      <!-- <template slot="extra">
        <el-button class="operate-close" type="primary" size="medium" plain @click="handleClose()">关闭</el-button>
      </template> -->
    </el-result>
    <div v-else-if="type === 2" class="success-ver-container">
      <div class="product-info">
        <div class="product-title">{{ productInfo.parentProductName }}</div>
        <div class="row">
          <div class="row-label">拍摄单品：</div>
          <div class="row-value">{{ productInfo.singleProductName }}</div>
        </div>
        <div class="row">
          <div class="row-label">预约时间：</div>
          <div class="row-value">{{ productInfo.appointmentShootDatetime }}</div>
        </div>
        <div class="row">
          <div class="row-label">拍摄门店：</div>
          <div class="row-value">{{ productInfo.appointmentStroe }}</div>
        </div>
      </div>
      <ul class="ver-input">
        <li
          v-for="(item, i) in inputs"
          :key="i"
          class="ver-input-item"
          :class="item.isCheck ? 'input-item-check' : ''"
          @click="handleInput(item, i)"
        >
          {{ item.num }}
        </li>
      </ul>
      <el-button
        class="ver-button"
        type="primary"
        :loading="submitLoadingStatus"
        @click="handleSubmitCode"
        >确认完成服务</el-button
      >

      <el-input
        ref="inputFocus"
        v-model="iptValue"
        type="tel"
        class="ver-el-input"
        minlength="1"
        :maxlength="6"
        @input="mirCode"
        @keydown.delete.native="mirKey"
      ></el-input>
    </div>
    <el-result v-else-if="type === 3" icon="success" title="已确认">
      <!-- <template slot="extra">
        <el-button class="operate-close" type="primary" size="medium" plain @click="handleClose()">关闭</el-button>
      </template> -->
    </el-result>
  </div>
</template>

<script>
import { verProduct, successServerOrder, getAfterProductInfo } from '@/api/order'
export default {
  data() {
    return {
      submitLoadingStatus: false,
      serviceId: undefined,
      writeOffNo: undefined,
      type: null,
      productInfo: {},
      iptValue: '',
      iptIndex: 0,
      inputs: [
        { num: undefined, isCheck: false },
        { num: undefined, isCheck: false },
        { num: undefined, isCheck: false },
        { num: undefined, isCheck: false },
        { num: undefined, isCheck: false },
        { num: undefined, isCheck: false },
      ],
    }
  },
  created() {
    document.title = this.$route.meta.title
    this.getData()
  },
  mounted() {
    // this.$refs.inputs0[0].focus();
  },
  methods: {
    getData() {
      try {
        this.serviceId = this.$route.query.serviceId
        this.writeOffNo = this.$route.query.writeOffNo
        // this.serviceId = 840;
        // this.writeOffNo = 20220228171510570004;
        const formData = {
          serviceId: this.serviceId,
          writeOffNo: this.writeOffNo,
          writeOffType: 2,
        }
        verProduct({ data: formData }).then((response) => {
          if (response.data === true) {
            this.type = 2
            getAfterProductInfo({ data: { serviceId: this.serviceId } }).then((response) => {
              this.productInfo = response.data
            })
          } else {
            this.type = 1
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    handleInput(item, index) {
      const currentItem = this.inputs.find((fi) => fi.isCheck)
      if (currentItem) {
        currentItem.isCheck = false
      }

      item.isCheck = !item.isCheck
      this.iptIndex = index
      this.$refs.inputFocus.focus()
    },
    mirCode(value) {
      if (this.iptIndex < this.inputs.length) {
        this.inputs[this.iptIndex].num = value
        const currentItem = this.inputs.find((fi) => fi.isCheck)
        currentItem.isCheck = false
      }
      if (this.iptIndex < this.inputs.length - 1) {
        this.iptIndex++
        this.inputs[this.iptIndex].isCheck = true
      }
      if (this.iptIndex === this.inputs.length - 1) {
        this.inputs[this.iptIndex].isCheck = true
      }
      this.iptValue = ''
    },
    mirKey() {
      if (this.iptIndex < 0) {
        this.iptValue = ''
        return
      }
      if (this.iptIndex === 0) {
        this.inputs[this.iptIndex].num = undefined
        this.inputs[0].isCheck = true
      }
      if (this.iptIndex > 0) {
        this.inputs[this.iptIndex].num = undefined
        this.inputs[this.iptIndex].isCheck = false
        this.inputs[this.iptIndex - 1].isCheck = true
        this.iptIndex--
      }
    },
    handleSubmitCode() {
      let str = ''
      this.inputs.forEach((fo) => {
        str += String(fo.num)
      })
      this.submitLoadingStatus = true
      const formData = {
        serviceId: this.serviceId,
        writeOffNo: str,
        writeOffType: 3,
      }
      successServerOrder({ data: formData })
        .then(() => {
          this.type = 3
          this.inputs = this.inputs.map((ma) => {
            ma.num = undefined
            return ma
          })
          this.submitLoadingStatus = false
        })
        .catch(() => {
          this.submitLoadingStatus = false
        })
    },
    handleClose() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.success-enter-server-container {
  position: relative;
  width: 100%;
  height: 100vh;

  .operate-close {
    width: 300px;
    margin-top: 60px;
  }
  .success-ver-container {
    .product-info {
      padding: 24px 36px;
      .product-title {
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }
      .row {
        display: flex;
        margin-top: 12px;
        color: #666;
      }
    }
    .ver-input {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      .ver-input-item {
        width: 52px;
        height: 52px;
        line-height: 48px;
        margin-right: 6px;
        border: 1px solid #c4bfbf;
        color: #000;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        &:last-child {
          margin: 0;
        }
      }
      .input-item-check {
        border: 1px solid #5381e8;
      }

      // .ver-input-item {
      //   margin-right: 6px;
      //   &:last-child {
      //     margin: 0;
      //   }
      // }
      // ::v-deep .el-input,
      // ::v-deep .el-input--small .el-input__inner {
      //   width: 56px;
      //   height: 56px;
      //   line-height: 56px;
      //   text-align: center;
      //   font-size: 32px;
      //   font-weight: bold;
      // }
    }
    .ver-el-input {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    .ver-button {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      font-size: 16px;
    }
  }
}
</style>
